import React from "react"

import Layout from "../components/Layout"

export default () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>You have navigated to an invalid route</p>
  </Layout>
)
